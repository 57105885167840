import React from 'react'

import kofiPng from './../img/kofi.png'
import kofiWebP from './../img/kofi.webp'
import licenceButtonImage from './../img/license-button.png'
import OutboundLink from './block-content/outboundLink'
import styles from './footer.module.css'
import SocialList from './socialList'
import { large } from './typography.module.css'

const Footer = ({ companyInfo }) => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerWrapper}>
        <p className={styles.companyAddress}>
          {companyInfo && (
            <>
              <code className={large}>{companyInfo.name}</code>
              <br />
              {companyInfo.address1}
              <br />
              {companyInfo.address2 && (
                <span>
                  {companyInfo.address2}
                  <br />
                </span>
              )}
              {companyInfo.zipCode} {companyInfo.city}
              {companyInfo.country && <span>, {companyInfo.country}</span>}
            </>
          )}
        </p>
        <OutboundLink noIcon className={styles.kofiLink} href='https://ko-fi.com/L4L43L4ZG' target='_blank'>
          <picture>
            <source type='image/webp' srcSet={kofiWebP} />
            <img
              height='36'
              loading='lazy'
              src={kofiPng}
              border='0'
              alt='Buy Me a Coffee at ko-fi.com'
            />
          </picture>
        </OutboundLink>
        <SocialList />
        <hr />
        <p className={styles.copyright}>
          Except where otherwise noted, this website is licensed under a {' '}
          <OutboundLink
            rel='license'
            href='http://creativecommons.org/licenses/by-nc-nd/4.0/'
            target='_blank'
          >
            Creative Commons Attribution-NonCommercial-NoDerivatives 4.0 International License
          </OutboundLink>.
          <OutboundLink
            rel='license'
            href='http://creativecommons.org/licenses/by-nc-nd/4.0/'
            target='_blank'
            noIcon
          >
            <img
              loading='lazy'
              className={styles.copyrightImage}
              alt='Creative Commons Licence'
              src={licenceButtonImage}
            />
          </OutboundLink>
        </p>
        <p className={styles.siteInfo}>
          © {new Date().getFullYear()}, Built with <OutboundLink target='_blank' href='https://www.sanity.io'>Sanity</OutboundLink> &amp;
          {' '}
          <OutboundLink target='_blank' href='https://www.gatsbyjs.org'>Gatsby</OutboundLink>
        </p>
      </div>
    </footer>
  )
}

export default Footer
