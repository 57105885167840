import '../styles/layout.css'

import React from 'react'

import { cn } from '../lib/helpers'
import Footer from './footer'
import Header from './header'
import styles from './layout.module.css'
import LightSwitch from './lightSwitch'
import { large } from './typography.module.css'

const Layout = ({ children, companyInfo, onHideNav, onShowNav, showNav, siteTitle }) => (
  <>
    <a className={cn(large, styles.skipToContentLink)} href='#main'>
      Skip to content
    </a>
    <LightSwitch />
    <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
    <main id='main' className={styles.content}>{children}</main>
    <Footer companyInfo={companyInfo} />
  </>
)

export default Layout
