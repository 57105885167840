import React from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'

import { cn } from '../lib/helpers'
import useIsMacOs from '../utils/useIsMacOs'
import useKeyboardListener from '../utils/useKeyboardListener'
import Icon from './icons'
import styles from './searchBox.module.css'

const SearchBox = connectSearchBox(
  ({ refine, currentRefinement, onFocus, hasFocus, hasResults }) => {
    const focusInputEl = () => document.querySelector(`.${styles.searchInput}`).focus()
    const isMacOs = useIsMacOs()
    useKeyboardListener('metaKey', 'k', focusInputEl)
    useKeyboardListener('ctrlKey', 'k', focusInputEl)

    return (
      <form
        className={cn(
          styles.searchBox,
          hasFocus ? styles.open : '',
          hasResults ? styles.hasResults : ''
        )}
      >
        <div className={styles.shortcut}>
          <kbd>{isMacOs ? '⌘' : 'ctrl'}</kbd>+<kbd>K</kbd>
        </div>
        <input
          className={styles.searchInput}
          type='text'
          placeholder=''
          aria-label='Search'
          onChange={e => refine(e.target.value)}
          value={currentRefinement}
          onFocus={onFocus}
        />
        <div className={styles.searchIcon} onClick={focusInputEl}>
          <Icon symbol='search' onClick={focusInputEl} />
        </div>
      </form>
    )
  }
)

export default SearchBox
