import React, { useContext, useEffect, useRef, useState } from 'react'

import { DarkModeContext } from '../context/DarkModeContext'
import { cn, isTouchDevice } from '../lib/helpers'
import styles from './lightSwitch.module.css'

const INITIAL_STRING_Y = -100
const STRING_HEIGHT = 170
const CLICK_Y_THRESHOLD = -60
const INITIAL_CLICK_Y = 0
const STRETCH_THRESHOLD = 35

const LightSwitch = () => {
  const [isGrabbing, setIsGrabbing] = useState(false)
  const [clicked, setClicked] = useState(false)
  const [clientY, setClientY] = useState(INITIAL_CLICK_Y)
  const [clickSound, setClickSound] = useState(null)
  const [unclickSound, setUnclickSound] = useState(null)

  const { toggleMode } = useContext(DarkModeContext)

  const containerRef = useRef(null)

  const initialise = () => {
    containerRef.current.style.transform = `translateY(${INITIAL_STRING_Y}px)`
    containerRef.current.style.height = `${STRING_HEIGHT}px`
    setIsGrabbing(false)
    setClicked(false)
    setClientY(INITIAL_CLICK_Y)
  }

  useEffect(() => {
    initialise()
  }, [containerRef])

  const setAudioSounds = async () => {
    if (typeof window === 'undefined') {
      return
    }
    if (!clickSound) {
      const clickFile = require('./../sounds/click.mp3')
      /* eslint-disable no-undef */
      setClickSound(new Audio(clickFile))
    }
    if (!unclickSound) {
      const unclickFile = require('./../sounds/unclick.mp3')
      /* eslint-disable no-undef */
      setUnclickSound(new Audio(unclickFile))
    }
  }

  const handleGrabStart = evt => {
    setIsGrabbing(true)
    setClientY(evt.clientY || evt.targetTouches[0].pageY)
    setAudioSounds()
  }

  const handleDrag = async evt => {
    const translateY = INITIAL_STRING_Y + (evt.clientY || evt.targetTouches[0].pageY) - clientY
    // *    don't move up    OR dont't move past string height
    if (translateY < INITIAL_STRING_Y || translateY >= 0) {
      return
    }
    if (translateY > CLICK_Y_THRESHOLD) {
      if (!clicked) {
        // * play click sound and don't move more
        setClicked(true)
        clickSound.play()
        const rotateY = Math.min(1500 / (translateY * -1), STRETCH_THRESHOLD)
        containerRef.current.style.transform = `translateY(${translateY + 5}px) rotateY(${rotateY}deg)`
      }
      return
    }
    const rotateY = Math.min(1500 / (translateY * -1), STRETCH_THRESHOLD)
    containerRef.current.style.transform = `translateY(${translateY}px) rotateY(${rotateY}deg)`
  }

  const handleRelease = () => {
    if (clicked) {
      unclickSound.play()
      toggleMode()
    }
    initialise()
  }

  const mouseEventListeners = {
    onMouseDown: handleGrabStart,
    onMouseMove: isGrabbing ? handleDrag : () => { },
    onMouseUp: handleRelease
  }
  const touchEventListeners = {
    onTouchStart: handleGrabStart,
    onTouchMove: isGrabbing ? handleDrag : () => { },
    onTouchEnd: handleRelease
  }
  const eventListeners = isTouchDevice() ? touchEventListeners : mouseEventListeners

  return (
    <>
      <div tabIndex='-1' className={styles.light} />
      <div
        {...eventListeners}
        className={cn(styles.container, isGrabbing ? styles.grabbing : '')}
        ref={containerRef}
      >
        <div className={styles.string}>
          <div className={styles.stringBall} />
          <div className={styles.stringBall} />
          <div className={styles.stringBall} />
          <div className={styles.stringBall} />
          <div className={styles.stringBall} />
          <div className={styles.stringBall} />
          <div className={styles.stringBall} />
          <div className={styles.stringBall} />
          <div className={styles.stringBall} />
          <div className={styles.stringBall} />
          <div className={styles.stringBall} />
          <div className={styles.stringBall} />
          <div className={styles.stringBall} />
          <div className={styles.stringBall} />
          <div className={styles.stringBall} />
          <div className={styles.stringBall} />
          <div className={styles.stringBall} />
          <div className={styles.stringBall} />
          <div className={styles.stringBall} />
          <div className={styles.stringBall} />
          <div className={styles.handle} />
        </div>
      </div>
    </>
  )
}

export default LightSwitch
