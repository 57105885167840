import { Link } from 'gatsby'
import React from 'react'
import {
  connectStateResults,
  Highlight,
  Hits,
  Index,
  Snippet
} from 'react-instantsearch-dom'

import { cn } from '../lib/helpers'
import styles from './searchResult.module.css'
import { small } from './typography.module.css'

const HitIndex = connectStateResults(({ searchResults }) => {
  if (!searchResults) {
    return null
  }
  const { nbHits, index } = searchResults

  return nbHits > 0 ? (
    <h3 className={styles.hitIndex}>{index}</h3>
  ) : null
})

const PageHit = ({ hit }) => (
  <div className={styles.hitWrapper}>
    <Link to={hit.slug}>
      <h4><Highlight attribute='title' hit={hit} tagName='mark' /></h4>
      <Highlight separator='' className={cn(styles.categoriesHighlight, small)} attribute='categories' hit={hit} tagName='mark' />
      <p className={cn(small, styles.excerpt)}>
        <Snippet attribute='excerpt' hit={hit} tagName='mark' />
      </p>
    </Link>
  </div>
)

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <HitIndex />
    <Hits className={styles.hits} hitComponent={PageHit} />
  </Index>
)

const SearchResult = ({ indices, show }) => (
  <div className={cn(styles.searchResults, show ? styles.hasResults : '')}>
    {show && indices.map(index => (
      <HitsInIndex index={index} key={index.name} />
    ))}
  </div>
)

export default SearchResult
