import { useEffect, useState } from 'react'

export default () => {
  const [isMacOs, setIsMacOs] = useState(null)

  useEffect(() => {
    setIsMacOs(
      (navigator.userAgentData && navigator.userAgentData.platform === 'macOS') ||
      navigator.userAgent.indexOf('Mac') !== -1
    )
  }, [])

  return isMacOs
}
