import algoliasearch from 'algoliasearch/lite'
import React, { useMemo, useRef, useState } from 'react'
import { InstantSearch } from 'react-instantsearch-dom'

import useClickOutside from '../utils/useClickOutside'
import useKeyboardListener from '../utils/useKeyboardListener'
import styles from './search.module.css'
import SearchBox from './searchBox'
import SearchResult from './searchResult'

const indices = [
  { name: 'Articles', title: 'Articles' },
  { name: 'Lazy hacks', title: 'Lazy hacks' },
  { name: 'Projects', title: 'Projects' }
]

const Search = () => {
  const ref = useRef()
  const [query, setQuery] = useState()
  const [hasFocus, setFocus] = useState(false)

  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  )

  useClickOutside(ref, () => setFocus(false))
  useKeyboardListener(null, 'Escape', () => {
    setFocus(false)
    ref.current.querySelector('input').blur()
  })

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={indices[0].name}
      onSearchStateChange={({ query }) => setQuery(query)}
    >
      <div ref={ref} className={styles.searchContainer}>
        <SearchBox
          onFocus={() => setFocus(true)}
          hasFocus={hasFocus}
          hasResults={query && query.length > 0}
        />
        <SearchResult
          show={query && query.length > 0 && hasFocus}
          indices={indices}
        />
      </div>
    </InstantSearch>
  )
}

export default Search
