import { useEffect } from 'react'
import { supportsPassive } from '../lib/helpers'

const events = ['mousedown', 'touchstart']

export default (ref, onClickOutside) => {
  const isOutside = element =>
    !ref.current ||
    !ref.current.querySelector(
      element.classList[0]
        ? '.' + element.classList.toString().split(' ').join('.')
        : '#' + element.id
    )

  const onClick = event => {
    if (!event.target.classList[0] && !event.target.id) {
      return
    }
    if (isOutside(event.target)) {
      onClickOutside()
    }
  }

  useEffect(() => {
    for (const event of events) {
      document.addEventListener(event, onClick, supportsPassive() ? { passive: true } : false)
    }

    return () => {
      for (const event of events) document.removeEventListener(event, onClick)
    }
  })
}
