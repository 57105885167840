import React, { useEffect, useState } from 'react'
import { FaRss } from 'react-icons/fa'

import { cn, supportsPassive } from '../lib/helpers'
// import OutboundLink from './block-content/outboundLink'
import styles from './header.module.css'
import Icon from './icons'
import Nav from './nav'
import Search from './search'

const Header = ({ onHideNav, onShowNav, showNav }) => {
  const [blurNavBackground, setBlurNavBackground] = useState(false)

  useEffect(() => {
    const pageWrapper = document.querySelector('#gatsby-focus-wrapper')
    const scrollEventListener = () => {
      if (pageWrapper.scrollTop > 50 && !blurNavBackground) {
        setBlurNavBackground(true)
      } else if (pageWrapper.scrollTop <= 50 && blurNavBackground) {
        setBlurNavBackground(false)
      }
    }

    pageWrapper.addEventListener('scroll', scrollEventListener, supportsPassive() ? { passive: true } : false)
    // * Fixes multiple event listeners being added on each Gatsby navigation
    // ? Only in Chrome Dev Tools: window.getEventListeners(pageWrapper)["scroll"]
    // * returned function will run before the component unmounts (similar to `componentWillUnmount`)
    return function cleanup () {
      pageWrapper.removeEventListener('scroll', scrollEventListener)
    }
  })

  return (
    <header className={cn(styles.root, blurNavBackground && styles.blurBackground)}>
      <div className={styles.wrapper}>
        <button
          title='Toggle navigation menu'
          className={styles.toggleNavButton}
          onClick={showNav ? onHideNav : onShowNav}
        >
          {showNav
            ? <Icon symbol='close' />
            : <Icon symbol='hamburger' />}
        </button>

        <div className={styles.branding}>
          {/* Don't use Gatsby Link to prevent prefetching calls to this route */}
          <a href='/rss.xml' title='Subscribe to my RSS feed'>
            <FaRss size={22} />
          </a>
        </div>

        <Search />
        <Nav showNav={showNav} />
      </div>
    </header>
  )
}

export default Header
