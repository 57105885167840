import { useEffect } from 'react'

import { isTouchDevice, supportsPassive } from '../lib/helpers'

/**
 * @param {String} modifier - ctrlKey | shiftKey
 * @param {String} key - i.e. k
 */
export default (modifier, key, callback) => {
  if (isTouchDevice()) {
    return
  }

  const listener = event => {
    if (modifier && event[modifier] && event.key === key) {
      // event.preventDefault()
      callback()
    } else if (!modifier && event.key === key) {
      // event.preventDefault()
      callback()
    }
  }

  useEffect(() => {
    if (isTouchDevice()) {
      return
    }
    document.addEventListener('keydown', listener, supportsPassive() ? { passive: true } : false)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [])
}
