import React from 'react'
import { FaBars, FaSearch, FaTimes } from 'react-icons/fa'

function Icon (props) {
  switch (props.symbol) {
    case 'hamburger':
      return <FaBars />
    case 'close':
      return <FaTimes />
    case 'search':
      return <FaSearch />

    default:
      return <span>Unknown icon: {props.symbol}</span>
  }
}

export default Icon
